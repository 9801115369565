import { j as r } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as s } from "react";
import { B as i } from "./Badge-C0bj4DFU.js";
import { T as m } from "./Text-Bn-rHqca.js";
import { F as o } from "./FileIcon.component-BXVWpVfU.js";
import { F as n } from "./FolderIcon.component-BB8KDJ0U.js";
const l = s((e, a) => {
  const t = e.type === "FOLDER" ? /* @__PURE__ */ r.jsx(n, { type: e.folderType }) : /* @__PURE__ */ r.jsx(o, { fileName: e.documentName });
  return /* @__PURE__ */ r.jsxs("div", { ref: a, className: `gap-2 cursor-move h-10 items-center p-2 shadow-md border-0 bg-popover flex rounded-md ${e.className}`, children: [
    /* @__PURE__ */ r.jsx("div", { className: "shrink-0", children: t }),
    /* @__PURE__ */ r.jsx(m, { className: "text-ellipsis overflow-hidden whitespace-nowrap", children: e.title }),
    e.count > 1 && /* @__PURE__ */ r.jsx(i, { variant: "warning", className: "w-5 h-5 inline-flex items-center justify-center", children: e.count })
  ] });
});
l.displayName = "ElementDragOverlay";
export {
  l as E
};
